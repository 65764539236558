import { Controller } from "@hotwired/stimulus";
import { Toast } from 'bootstrap';

// Connects to data-controller="bootstrap--toast"
export default class extends Controller {
  connect() {
    const toast = new Toast(this.element);
    toast.show();
  }
}
