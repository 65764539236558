import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="tom-select--select"
export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      plugins: ["remove_button"],
      maxOptions: null,
      render: {
        option: (data, escape) => this.#renderOptionOrItem(data, escape),
        item: (data, escape) => this.#renderOptionOrItem(data, escape)
      }
    });
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }

  #renderOptionOrItem(data, escape) {
    let iconContent = '';
    const iconClasses = 'me-2 font-size-responsive-select-option-icon';

    if (data.colorCode) {
      iconContent = `<div class="${iconClasses}" style="background-color:#${escape(data.colorCode)};"></div>`;
    } else if (data.pictureUrl) {
      iconContent = `<img class="${iconClasses}" src="${escape(data.pictureUrl)}"/>`;
    }

    return `
      <div class="d-flex align-items-center">
        ${iconContent}
        <div>${escape(data.text)}</div>
      </div>
    `;
  }
}
