import { Controller } from "@hotwired/stimulus";

async function initializeStripeCheckoutForm(publishableKeyValue, clientSecretValue) {
  const stripe = Stripe(publishableKeyValue);

  const checkoutForm = await stripe.initEmbeddedCheckout({
    clientSecret: clientSecretValue,
  });

  return checkoutForm;
}

// Connects to data-controller="stripe--embedded-checkout-form"
export default class extends Controller {
  static values = { publishableKey: String, clientSecret: String };

  connect() {
    this.initializeCheckoutForm();
  }

  async initializeCheckoutForm() {
    this.checkoutForm = await initializeStripeCheckoutForm(this.publishableKeyValue, this.clientSecretValue);
    this.checkoutForm.mount(this.element);
  }

  disconnect() {
    if (this.checkoutForm) {
      this.checkoutForm.destroy();
    }
  }
}
